<template>
  <v-row>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            x-small
            color="primary"
            dark
            v-bind="attrs"
            class="ma-2 text-decoration-none"
            v-on="on"
        >
          Manage categories
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
            dense
            dark
            color="secondary"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Manage categories</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="update"
            >
              Save
            </v-btn>
            <v-btn
                dark
                text
                @click="dialog=false"
            >
              Back
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="categories"
            item-key="name"
            show-select
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Manage categories</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  props: ['course'],
  data() {
    return {
      dialog: false,
      categories: [],
      selected: [],
      headers: [
        {
          text: 'Category',
          align: 'start',
          value: 'name',
        },
      ],
    }
  },
  computed: {
    selectedIds() {
      return this.selected.map(a => a.id)
    },
    form() {
      let formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('type', 'category')
      formData.append('ids', JSON.stringify(this.selectedIds))
      return formData
    }
  },
  watch:{
    dialog(){
      if (this.dialog) {
        this.initialize()
      }
    }
  },
  created() {
  },
  methods: {
    initialize() {
      let url = 'admin/categories'
      axios.get(url).then((response) => {
        this.categories = response.data
        let url = 'admin/courses/' + this.course.id + '/categories'
        axios.get(url).then((response) => {
          this.selected = response.data
        })
      }).catch(() => {

      })
    },
    update() {
      const url = 'admin/courses/' + this.course.id
      axios.post(url, this.form).then(() => {
        this.initialize()
      })
    }
  }
}
</script>
<style scoped>
.text-decoration-none {
  text-decoration: none;
}
</style>