<template>
  <v-list two-line>
    <v-list-item-group
        v-model="selected"
        active-class="pink--text"
    >
      <draggable
          v-model="sortedCourses"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div v-for="(course, index) in sortedCourses" :key="uniqueId+index">
            <v-list-item :ripple="false" :key="index+course.id+uniqueId">

              <template v-slot:default>
                <v-list-item-avatar @click="followLink(course.id)" rounded min-width="80" min-height="60">
                  <v-img
                      :alt="`${course.title}`"
                      :src="course.photo"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="course.title" @click="followLink(course.id)"></v-list-item-title>

                  <v-list-item-subtitle
                      class="text--primary"
                      v-text="course.sub_title"
                      @click="followLink(course.id)"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <div class="d-flex">
                      <div>
                        <div>
                          ৳{{ price(course) }}
                        </div>
                      </div>
                      <router-link :to="{name:'CourseUserList',params:{courseId: course.id}}" class="mx-2">
                        | {{ course.users_count }} students
                      </router-link>
                      <router-link :to="{name: 'ReportedMcqs', params:{courseId: course.id}}" class="mx-2">
                        | Reported questions
                      </router-link>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <manage-categories :course="course"></manage-categories>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                      icon
                      :disabled="busy"
                      @click="copy(course)"
                  >
                    <v-icon
                        color="blue lighten-1"
                    >
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                  <v-btn icon @click="editCourse(course)">
                    <v-icon
                        color="blue lighten-1"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteCourse(course)">
                    <v-icon
                        color="red darken-3"
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
                v-if="index < courses.length - 1"
                :key="uniqueId+index"
            ></v-divider>
          </div>
        </transition-group>
      </draggable>
    </v-list-item-group>
  </v-list>
</template>

<script>
import {v4 as uuidv4} from 'uuid'
import draggable from "vuedraggable";
import axios from "axios";
import ManageCategories from "./ManageCategories";
import Swal from "sweetalert2";

export default {
  name: "CourseListGroup",
  components: {ManageCategories, draggable},
  props: ['courses'],
  data() {
    return {
      busy: false,
      selected: false,
      sortedCourses: [],
      drag: false,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  methods: {
    sortItems() {
      const url = 'admin/courses'
      let data = {
        courses: this.sortedCourses,
        type: 'sort'
      }
      axios.post(url, data).then(() => {
      })
    },
    uniqueId() {
      return uuidv4.v4()
    },
    price(course) {
      let price = course.price
      let discount = course.discount || 0
      return course.is_paid ? price - discount : 'Free '
    },
    editCourse(course) {
      this.$emit('editCourse', course)
    },
    deleteCourse(course) {
      this.$emit('deleteCourse', course)
    },
    followLink(id) {
      this.$router.push({name: 'InsertVideo', params: {courseId: id}});
    },
    copy(course) {
      this.busy = true
      const url = 'admin/courses/copy'
      let formData = new FormData()
      formData.append('course_id', course.id)
      axios.post(url, formData).then(() => {
        Swal.fire('Course copied successfully!')
        this.busy = false
        this.$emit('copied')
      }).catch(() => {
        this.busy = false
        Swal.fire('Failed', 'Something went wrong!', 'error')
      })
    }
  },
  watch: {
    courses() {
      this.sortedCourses = this.courses
    },
    sortedCourses(newVal, oldVal) {
      if (oldVal.length) {
        this.sortItems()
      }
    }
  }
}
</script>

<style>
.button {
  margin-top: 35px
}

.flip-list-move {
  transition: transform 0.5s
}

.no-move {
  transition: transform 0s
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb
}

.list-group {
  min-height: 20px
}

.list-group-item {
  cursor: move
}

.list-group-item i {
  cursor: pointer
}
</style>