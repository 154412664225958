<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="800px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="createCourse"
          >
            Create new course
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">Course details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="takeAction">
                <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                >
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-switch
                          v-model="is_paid"
                          label="Paid"
                      ></v-switch>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                      <v-switch
                          v-model="is_featured"
                          label="Featured"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12">
                      <v-select
                        :items="[{name: 'Science',value: 'science'},{name: 'Commerce',value: 'commerce'},{name: 'Arts',value: 'arts'}]"
                        item-text="name"
                        item-value="value"
                        v-model="type"
                        label="Choose type"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Title"
                          rules="required"
                      >
                        <v-text-field
                            v-model="title"
                            :error-messages="errors"
                            :counter="50"
                            label="Course title"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Subtitle"
                          rules="required"
                      >
                        <v-text-field
                            v-model="sub_title"
                            :error-messages="errors"
                            :counter="60"
                            label="Course subtitle title"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col
                        v-if="is_paid"
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Price"
                          rules="required"
                      >
                        <v-text-field
                            v-model="price"
                            :error-messages="errors"
                            label="Course price"
                            required
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col
                        v-if="is_paid"
                        cols="12"
                        sm="12"
                        md="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          name="Discount"
                      >
                        <v-text-field
                            v-model="discount"
                            :error-messages="errors"
                            label="Course discount"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <v-datetime-picker
                        ref="discountEndsAt"
                        v-model="discount_ends_at"
                        :text-field-props="{prependIcon: 'mdi-calendar'}"
                        label="Discount ends at"
                        time-format="hh:mm:ssa"
                        >
                        <template slot="dateIcon">
                          <v-icon>mdi-clock</v-icon>
                        </template>
                      </v-datetime-picker>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-file-input
                          chips
                          label="Choose course photo"
                          v-model="photo"
                      ></v-file-input>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="video"
                          label="Enter video link"
                      ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <quill-editor
                          :options="{placeholder: 'Enter course summery'}"
                          v-model="summery"
                      ></quill-editor>
                    </v-col>

                    <v-col
                        cols="12"
                    >
<!--                      <quill-editor-->
<!--                          :options="{placeholder: 'Enter course description'}"-->
<!--                          v-model="description"-->
<!--                      ></quill-editor>-->
                      <tiptap-vuetify
                          v-model="description"
                          :extensions="extensions"
                          placeholder="Enter course description"
                      >
                      </tiptap-vuetify>
                    </v-col>

                    <v-col
                        cols="12"
                    >
                      <quill-editor
                          :options="{placeholder: 'Enter course requirements'}"
                          v-model="requirements"
                      ></quill-editor>
                    </v-col>

                    <v-btn
                        class="mr-4"
                        type="submit"
                        :disabled="invalid"
                        :loading="loading"
                    >
                      {{ editMode ? 'update' : 'submit' }}
                    </v-btn>
                    <v-btn @click="clear">
                      clear
                    </v-btn>
                  </v-row>
                </validation-observer>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <admin-course-lists :courses="courses" @updated="loadCourses" @edit="edit"></admin-course-lists>
    </v-col>
  </v-row>
</template>
<script>
import {required} from 'vee-validate/dist/rules'
import AdminCourseLists from './AdminCourseLists.vue'
import axios from 'axios'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    AdminCourseLists,
    TiptapVuetify,
  },
  data: () => ({
    loading: false,
    courses: '',
    selectedCourse: null,
    editMode: false,
    dialog: false,
    title: '',
    sub_title: '',
    type: 'academic',
    summery: '',
    description: '',
    requirements: '',
    is_paid: '',
    is_featured: '',
    price: '',
    discount: '',
    discount_ends_at: '',
    photo: null,
    video: '',
    snackbar: false,
    text: '',
    color: 'blue',
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  computed: {
    form: function () {
      let formData = new FormData();
      if (this.editMode) {
        formData.append('_method', 'PUT');
      }
      formData.append('title', this.title);
      formData.append('sub_title', this.sub_title);
      formData.append('summery', this.summery ? this.summery : '');
      formData.append('description', this.description ? this.description : '');
      formData.append('requirements', this.requirements ? this.requirements : '');
      formData.append('is_paid', this.is_paid ? 1 : 0);
      formData.append('is_featured', this.is_featured ? 1 : 0);
      formData.append('price', this.price);
      formData.append('discount', this.discount);
      formData.append('discount_ends_at', moment(this.discount_ends_at).isValid() ? moment(this.discount_ends_at).format('DD-MMM-YYYY hh:mm:ssa') : '')
      formData.append('video',this.video);
      formData.append('type',this.type)
      if (this.photo) {
        formData.append('photo', this.photo);
      }
      return formData;
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    createCourse() {
      this.editMode = false
      this.selectedCourse = null
      this.title = ''
      this.sub_title = ''
      this.summery = ''
      this.description = ''
      this.requirements = ''
      this.is_paid = ''
      this.is_featured = ''
      this.price = ''
      this.discount = ''
      this.discount_ends_at = ''
      this.video = ''
      this.type = 'academic'
      this.photo = null
    },
    edit(course) {
      this.dialog = true;
      this.editMode = true;
      this.title = course.title;
      this.sub_title = course.sub_title;
      this.summery = course.summery ? course.summery : '';
      this.description = course.description ? course.description : '';
      this.requirements = course.requirements ? course.requirements : '';
      this.is_paid = course.is_paid === 1;
      this.is_featured = course.is_featured === 1;
      this.price = course.price;
      this.discount = course.discount;
      this.discount_ends_at = moment(course.discount_ends_at).isValid() ? moment(course.discount_ends_at).toDate() : ''
      console.log(course)
      this.video = course.video;
      this.selectedCourse = course;
      this.type = course.type || 'academic'
    },
    submit() {
      const url = 'admin/courses';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New course created';
        this.loadCourses();
      })
    },
    update() {
      const url = 'admin/courses/' + this.selectedCourse.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Course updated';
        this.loadCourses();
      })
    },
    loadCourses() {
      const url = 'admin/courses?filter=admin';
      axios.get(url).then((response) => {
        this.editMode = false;
        this.courses = response.data;
      })
    },
    clear() {
      this.title = '';
      this.sub_title = '';
      this.summery = '';
      this.description = '';
      this.requirements = '';
      this.is_paid = '';
      this.is_featured = '';
      this.price = '';
      this.discount = '';
      this.discount_ends_at = ''
      this.video = '';
      this.photo = null;
      this.type = 'academic'
      this.dialog = false;
      this.selectedCourse = null;
      this.$refs.observer.reset();
    },
  },
  mounted() {
    this.loadCourses();
  }
}

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
</script>
