<template>
  <v-container fluid>
    <v-row dense v-if="!courses">
      <v-col v-for="(index) in 12" :key="index"
             cols="12"
      >
        <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <course-list-group @copied="copiedCourse" @editCourse="editCourse" @deleteCourse="deleteCourse"
                       :courses="courses"></course-list-group>
  </v-container>
</template>
<script>
import Swal from 'sweetalert2'
import axios from 'axios'
import CourseListGroup from "./CourseListGroup";

export default {
  components: {CourseListGroup},
  props: ['courses'],
  data: () => ({
    attrs: {
      boilerplate: true,
      elevation: 2,
    },
  }),
  methods: {
    followLink(id) {
      this.$router.push({name: 'InsertVideo', params: {courseId: id}});
    },
    editCourse(course) {
      this.$emit('edit', course);
    },
    deleteCourse(course) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Write 'confirm' in the text box to confirm delete action.",
        icon: 'warning',
        input: 'text',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        inputValidator: (value) => {
          if (value.toLowerCase() !== 'confirm') {
            return 'Write "confirm" in the text box.'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/courses/' + course.id;
          axios.delete(url).then(() => {
            Swal.fire(
                'Deleted!',
                '',
                'success'
            );
            this.$emit('updated');
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      });
    },
    copiedCourse() {
      this.$emit('updated')
    }
  }
}
</script>